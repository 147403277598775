import React, { useState } from "react";
import { graphql } from "gatsby";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useTranslations } from "../hooks/translations";

// import BannerBackground from "../assets/photos/hero-volunteer.jpg";

import SEO from "../components/seo";
import Hero from "../components/home/hero/hero";
import WhoWeAre from "../components/home/who-we-are";
import ViolationsPreview from "../components/violations-preview";
import PartnersPreview from "../components/home/partners-preview";
import StatusModal from "../components/status-modal";
// import NewsPreview from "../components/home/news-preview";
// import Banner from "../components/banner";
// import StatisticsPreview from "../components/home/statistics-preview";

export const query = graphql`
  query IndexQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        index
      }
      enums {
        violationType
        region
      }
      hero
      violations
      partners
      statisticsTable
      ...LayoutTranslations
    }
  }
`;

const IndexPage = () => {
  const t = useTranslations("pages.index");

  const [subscribed] = useQueryParam(t.subscribed.urlParam, BooleanParam);

  const [displaySubscribedDialog, setDisplaySubscribedDialog] = useState(subscribed);

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      {/* This div is needed because this component is placed inside a flex container */}
      <div>
        <Hero />
      </div>

      <WhoWeAre />

      <ViolationsPreview displayRegisterButton />

      <PartnersPreview />

      {displaySubscribedDialog ? (
        <StatusModal
          heading={t.subscribed.heading}
          description={t.subscribed.description}
          onClose={() => setDisplaySubscribedDialog(false)}
        />
      ) : null}

      {/* Temporarily hidden */}
      {/* <NewsPreview />

      <Banner
        heading={t.intermission.heading}
        description={t.intermission.description}
        background={BannerBackground}
      />

      <StatisticsPreview /> */}
    </>
  );
};

export default IndexPage;
